import { RightOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation } from '@reach/router';
import { Button, message } from 'antd';
import { Link, navigate } from 'gatsby';
import { get, map } from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Textfit } from 'react-textfit';

import { addressWhiteIconImg, agreementIconImg, csWhiteImg, customer_serviceIcon, settingWhiteImg } from '../../../images';
import { sourceKey } from '../../../locales/config';
import { useTranslation } from '../../../locales/useTranslation';
import getPairingUser from '../../../newApi/pairing/getPairingUser';
import generateDemoAccessKey from '../../../newApi/profile/generateDemoAccessKey';
import getProfiles from '../../../newApi/profile/getProfiles';
import { clearModal } from '../../../redux/actions/app-actions';
import { logoutSuccessful } from '../../../redux/actions/user-actions';
import { routes } from '../../../route';
import { formatDate } from '../../../utilities/common-function';
import { demoURl } from '../../../utilities/startUp';
import APIAgreementModal from '../../apiKey/components/APIAgreementModal';
import Layout from '../../general/components/Layout';
import NavHeader from '../../general/components/NavHeader';

// markup
const ProfilePage = (props) => {
  const [user, setUser] = useState({});
  const { t } = useTranslation();

  const location = useLocation();

  const isAuthenticated = get(props, "user.isAuthenticated");

  const [agreementVisible, setAgreementVisible] = useState(false);

  const [profile, setProfile] = useState({});
  const [pairingProfile, setPairingProfile] = useState({});

  // console.log(props);

  useEffect(() => {
    setUser(get(props, "user.user"));
  }, [props.user.user]);

  useEffect(() => {
    getProfileAPI();
  }, []);

  function getProfileAPI() {
    if (get(props.user, "user.profile")) {
      getProfiles(1, 0, {
        _id: get(props.user, "user.profile._id"),
      }).then((res) => {
        setProfile(get(res, "data[0]"));
        // console.log(get(res, "data[0]"));
      });

      getPairingUser(1, 0, {
        _id: get(props.user, "user.profile.pairingUserId"),
        populate: [
          {
            service: "rankings",
            foreignId: "rankingId",
          },
        ],
      })
        .then((res) => {
          // console.log(res);
          let data = get(res, "data");
          data = data[0];
          setPairingProfile(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const menuItems = [
    {
      text: t("withdrawalAddress", sourceKey.profile),
      textEn: "Withdrawal Address",
      // icon: addressIcon,
      icon: addressWhiteIconImg,
      path: routes.address.to(),
    },

    // {
    //   text: t("inviteFriend", sourceKey.profile),
    //   textEn: "Invite Friends",
    //   // icon: invite_friendIcon,
    //   icon: inviteFriendWhiteImg,
    //   path: routes.inviteFriend.to(),
    // },

    {
      text: t("showAgreement", sourceKey.profile),
      textEn: "Show Agreement",
      // icon: customer_serviceIcon,
      icon: agreementIconImg,
      path: null,
      onClick: () => {
        setAgreementVisible(true);
      },
    },
    {
      text: t("customerService", sourceKey.profile),
      textEn: "Customer Service",
      icon: customer_serviceIcon,
      icon: csWhiteImg,
      path: routes.customerService.to(),
    },
    {
      text: t("profileSetting", sourceKey.profile),
      textEn: "Profile Setting",
      icon: settingWhiteImg,
      path: routes.setting.to(),
    },
    {
      text: t("demo", sourceKey.profile),
      textEn: "Demo",
      icon: settingWhiteImg,
      onClick: () => {
        // window.location.href = routes.setting.to();
        generateDemoAccessKey({
          profileId: get(profile, "demoUserId"),
        })
          .then((res) => {
            // console.log(res);
            let data = get(res, "data");
            let queryStringified = queryString.stringify(data);
            let fullUrl = `${demoURl}?${queryStringified}`;
            // console.log(fullUrl);
            window.location.href = fullUrl;
          })
          .catch((err) => {
            message.error(err?.message);
            console.log(err);
          });
        // window.location.assign("https://demo.myits.co/login");

        // window.open("https://demo.myits.co/login", "_blank");
      },
    },
  ];

  function logout() {
    props.logoutSuccessful();
    props.clearModal();
    message.success(t("logout"));
    if (typeof window !== `undefined`) {
      navigate(routes.home.to());
    }
  }

  return (
    <React.Fragment>
      <Layout>
        <NavHeader
          header={
            <div className="px-5 pt-3 ">
              {isAuthenticated ? (
                <div className="m-2 bg-main-color-gradient rounded-2xl px-4 pt-2">
                  <div className="">
                    <span
                      className="inline-block rounded-full border-4 border-white flex items-center justify-center"
                      style={{ width: 50, height: 50 }}
                    >
                      <UserOutlined style={{ fontSize: 40, color: "white" }} />
                    </span>
                  </div>
                  {/* <div className="text-base font-semibold">用户名称</div> */}
                  <div className="flex justify-start items-center mb-1">
                    <span className="inline-block w-1/3 text-base text-white font-semibold">
                      {t("username", sourceKey.profile)}
                    </span>
                    <Textfit className="inline-block w-2/3 text-base text-white font-semibold">
                      <span className="mr-3">:</span>
                      {get(user, "username")}{" "}
                      {get(pairingProfile, "populatedRankingId.level")
                        ? `(${t("level", sourceKey.profile)} - ${get(
                            pairingProfile,
                            "populatedRankingId.level"
                          )})`
                        : ""}
                    </Textfit>
                  </div>
                  {/* <div className="text-base font-semibold">UID</div> */}
                  <div className="flex justify-start items-center mb-1">
                    <span className="inline-block w-1/3 text-base text-white font-semibold">
                      UID
                    </span>
                    <Textfit className="inline-block w-2/3 text-base text-white font-semibold">
                      <span className="mr-3">:</span>
                      {get(user, "referralCode")}
                    </Textfit>
                  </div>
                  {/* <div className="text-base font-semibold">邮箱</div> */}
                  <div className="flex justify-start items-center pb-1">
                    <span className="inline-block w-1/3 text-base text-white font-semibold">
                      {t("email")}
                    </span>
                    <Textfit className="inline-block w-2/3 text-base text-white font-semibold">
                      <span className="mr-3">:</span>
                      {get(profile, "email")}
                    </Textfit>
                  </div>
                  <div className="flex justify-start items-center pb-1">
                    <span className="inline-block w-1/3 text-base text-white font-semibold">
                      {t("createdAt")}
                    </span>
                    <Textfit className="inline-block w-2/3 text-base text-white font-semibold">
                      <span className="mr-3">:</span>
                      {formatDate(get(user, "createdAt"), null)}
                    </Textfit>
                  </div>
                </div>
              ) : (
                <div className="m-2 bg-main-color-gradient rounded-2xl p-4">
                  <div className="">
                    <span
                      className="inline-block rounded-full border-4 border-white flex items-center justify-center"
                      style={{ width: 50, height: 50 }}
                    >
                      <UserOutlined style={{ fontSize: 40, color: "white" }} />
                    </span>
                  </div>
                  <div
                    className="flex items-center mb-3"
                    onClick={(e) => {
                      if (!isAuthenticated && typeof window !== `undefined`)
                        navigate(routes.login.to());
                    }}
                  >
                    <span className="inline-block  mr-4">
                      {/* <div className="text-base font-semibold">注册/登录</div> */}
                      <div className="text-base font-semibold">
                        {t("signUpAndLogin", sourceKey.profile)}
                      </div>
                    </span>
                    <RightOutlined style={{ fontSize: 30 }} />
                  </div>
                </div>
              )}
            </div>
          }
        >
          <div className="px-3 pb-2">
            <div className="mt-1">
              {map(menuItems, (item, index) => {
                return item.path ? (
                  <Link
                    to={item.path}
                    state={{ prevPath: location.pathname }}
                    key={`menu-${index}`}
                  >
                    <div
                      className="px-5 py-4 rounded-lg  lightgreybox-bg-color cursor-pointer mx-4 mb-4 flex justify-start items-center"
                      style={{
                        boxShadow: "0px 1px 10px rgba(254, 249, 252, 0.8)",
                      }}
                    >
                      <img
                        src={item.icon}
                        className="mr-4"
                        style={{ width: 20, height: 20 }}
                      />
                      <span className="inline-block ">
                        <div className="text-sm">{item.text}</div>
                        {/* <div className="text-sm">{item.textEn}</div> */}
                      </span>
                    </div>
                  </Link>
                ) : (
                  <div
                    className="px-5 py-4 rounded-lg  lightgreybox-bg-color mx-4 mb-4  cursor-pointer flex justify-start items-center"
                    key={`menu-${index}`}
                    onClick={(e) => {
                      item.onClick(item);
                    }}
                    style={{
                      boxShadow: "0px 1px 10px rgba(254, 249, 252, 0.8)",
                    }}
                  >
                    <img
                      src={item.icon}
                      className="mr-4"
                      style={{ width: 20, height: 20 }}
                    />
                    <span className="inline-block ">
                      <div className="text-sm">{item.text}</div>
                      {/* <div className="text-sm">{item.textEn}</div> */}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className="flex justify-center items-center mx-4 mt-3 px-2 pb-10"
            onClick={(e) => {
              logout();
            }}
          >
            <Button
              block
              shape="round"
              className="main-button-color input-border"
            >
              {t("logout", sourceKey.profile)}
            </Button>
          </div>
        </NavHeader>
      </Layout>

      <APIAgreementModal
        visible={agreementVisible}
        data={user}
        onClose={() => {
          setAgreementVisible(false);
        }}
      ></APIAgreementModal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  logoutSuccessful,
  clearModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
